<template>
  <div class="">
    <div class="position- w-100">
      <Navbar navbarAbsolute transparent="true"/>
    </div>
    <div class="ql-editor p-0">
      <div class="my-5 position-relative" style="padding: 5em 0">
        <img src="@/static/assets/contacts/item2.png" class="position-absolute" />
        <img
          src="@/static/assets/contacts/item2.png"
          class="position-absolute"
          style="transform: rotate(180deg); right: 0; top: 300px"
        />
        <!-- <div class="py-4 text-center d-flex flex-column align-items-center">
        <h1 style="max-width: 550px" class="font-weight-bold text-primary-darker">
          We Are Proud To Be Your Closest Partner Success
        </h1>
        <p style="max-width: 700px" class="mt-4">
          Your success is our dream. In the midst of changes that occur so quickly, we
          strive to be your closest partner who is always present to grow and develop
          together to achieve success.
        </p>
      </div> -->
        <div id="CONT-1" class="mx-auto" style="max-width: 550px;"></div>
      </div>
      <div>
        <div class="w-100" style="">
          <div class="container">
            <div class="d-flex" style="">
              <div
                class="mx-auto p-5"
                style="
                  margin-bottom: -50%;
                  z-index: 1;
                  background: linear-gradient(148.06deg, #0f6bb4 37.6%, #0b5087 102.33%);
                  box-shadow: 5px 50px 100px #657480;
                  border-radius: 24px;
                "
              >
                <!-- <h2 class="text-white">
                Please Complete The Following to connect with us!
              </h2> -->
                <div id="CONT-2"></div>
                <div
                  class="p-3 bg-light mt-3"
                  style="border-radius: 24px; max-width: 668px"
                >
                  <form id="frmbox">
                    <div class="row">
                    <div class="col-lg-6 mb-1">
                      <div class="form-group position-relative">
                        <label><span id="CONT-3">Full Name</span></label>
                        <b-form-input 
                          v-model="content.fullname"
                          type="text"
                          placeholder="your name"
                          :class="`${!content.fullname & isSubmited ? 'is-invalid' : ''} form-control`"
                          required
                        ></b-form-input>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group">
                        <label><span id="CONT-4">Company Name</span></label>
                        <b-form-input
                          v-model="content.company"
                          type="text"
                          placeholder="your company name"
                          required
                          :class="`${!content.company & isSubmited ? 'is-invalid' : ''} form-control`"
                        ></b-form-input>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group">
                        <label><span id="CONT-5">Email</span></label>
                        <b-form-input type="text"
                           v-model="content.email" required placeholder="your email"
                          :class="`${!content.email & isSubmited ? 'is-invalid' : ''} form-control`">
                          </b-form-input>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group">
                        <label><span id="CONT-6">Phone Number</span></label>
                        <b-form-input
                          v-model="content.phone"
                          type="text"
                          placeholder="your phone number"
                          required
                          :class="`${!content.phone & isSubmited ? 'is-invalid' : ''} form-control`"
                        ></b-form-input>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group">
                        <label><span id="CONT-7">Your Industry</span></label>
                        <select class="custom-select" v-model="content.industry" :class="`${!content.industry & isSubmited ? 'is-invalid' : ''} form-control`">
                          <option v-for="item in ['Trading/Distribution', 'Manufacturing', 'Retail', 'Services', 'Consultant', 'Construction',
                          'Human Resource', 'Hotel', 'Educational', 'Other Industry']" :key="item" :value="item">{{item}}</option>
                        </select>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group">
                        <label
                          ><span id="CONT-8">The solution you're looking for</span></label
                        >
                        <select class="custom-select" v-model="content.solution" :class="`${!content.solution & isSubmited ? 'is-invalid' : ''} form-control`">
                          <option value="Business Solution">Business Solution</option>
                          <option value="IT Solutions">IT Solutions</option> 
                        </select>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-1">
                      <div class="form-group">
                        <label><span id="CONT-9">Message</span></label>
                        <b-form-textarea
                          v-model="content.message"
                          rows="5"
                          placeholder="your message"
                          required
                          :class="`${!content.message & isSubmited ? 'is-invalid' : ''} form-control`"
                        ></b-form-textarea>
                        <div class="invalid-tooltip">
                          <span>This value is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </form>
                  <div class="d-flex justify-content-end">
                    <button
                      @click="sendData()"
                      class="btn rounded"
                      style="background-color: #0b5087; color: white"
                    >
                      <span class="text-white" id="CONT-10">Send Message</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="@/static/assets/contacts/item1.png"
          class="w-100"
          style="
            object-fit: cover;
            object-position: top;
            margin-top: 15em;
            max-height: 800px;
          "
        />
      </div>
    </div>
    <Footer />
    <FABHelp />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import FABHelp from "@/components/FAB.vue";
import { BFormInput, BFormTextarea } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    Navbar,
    Footer,
    FABHelp,
    BFormInput,
    BFormTextarea,
  },
  methods: {
    async sendData(){
      this.isSubmited = true
      // check validity
      if(this.content.fullname && this.content.company && this.content.email && this.content.phone && this.content.industry && this.content.solution && this.content.message){
        // send data
        let data = await store.dispatch("contacts/sendForm", this.content)
        if(data == 'nice'){
          // reset form
          this.content = {}
          this.isSubmited = false
          this.$noty.info("Your form has been submited!")
        }
      }
    },  
    async getNextDatas() {
      let temp = await store.dispatch(`page_content/getDataList`, {
        lang_code: this.currentLocale,
        code: "CONT",
      });
      temp?.map((item, key) => {
        document.getElementById(item.code).innerHTML = item.content;
      });
      this.$forceUpdate();
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  mounted() {
    this.getNextDatas();
  },
  data() {
    return {
      page_content: {},
      content : {},
      isSubmited: false,
      value: "",
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa",
      ],
    };
  },
};
</script>

<style scoped>
input,
select,
textarea {
  border: 1px solid #a6b6c2;
  border-radius: 12px;
  /* padding:14px */
}
input {
  padding: 20px 15px;
}

select {
  height: 42px;
}

textarea {
  padding: 15px;
}
</style>
